<template>
  <div class="p-3">
    <appRightToWorkInAustraliaView
      :propsData1="getUserFromId"
      :propsData2="getRightWorkAustralia"
    ></appRightToWorkInAustraliaView>
  </div>
</template>
<script>
import appRightToWorkInAustraliaView from "../../components/checkRightToWorkInAustralia/RightToWorkInAustraliaView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "RightToWorkInAustraliaView",
  components: {
    appRightToWorkInAustraliaView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getRightWorkAustralia", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchRightWorkAustralia", "fetchUserFromId"]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchRightWorkAustralia(this.projectId);
  },
};
</script>

